//
// terms.js
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from '../cmComponent';
import Page from '../page';
import React from "react";
import { Container, Header, List } from 'semantic-ui-react';

export default class TermsPage extends CMComponent {
  render() {
    return (
      <Page 
        id='terms' 
        nav='home' 
        title='Terms and Conditions' 
        showLogo={this.props.showLogo} 
        setActive={this.props.setActive}
      >
        <Container>
          <p>These terms and conditions outline the rules and regulations for the use of the Website owned by Cecala Music, LLC (hereinafter "us", "we", "Cecala Music", or "www.cecalamusic.com").</p>
          <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use Cecala Music's website
            if you do not accept all of the terms and conditions stated on this page.</p>
          <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
          and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website
          and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers
          to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client
          or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake
          the process of our assistance to the Client in the most appropriate manner, whether by formal meetings
          of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in respect
          of provision of the Company's stated services/products, in accordance with and subject to, the prevailing law
          of the State of Utah, USA. Any use of the above terminology or other words in the singular, plural,
            capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

          <Header as='h3'>Cookies</Header>
          <p>We employ the use of cookies. By using Cecala Music's website you consent to the use of cookies
            in accordance with Cecala Music's privacy policy.</p>
          <p>Most of the modern day interactive web sites
          use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site
          to enable the functionality of this area and ease of use for those people visiting. Some of our
            affiliate / advertising partners may also use cookies.</p>

          <Header as='h3'>License</Header>
          <p>Unless otherwise stated, Cecala Music and/or it's licensors own the intellectual property rights for
          all material on Cecala Music. All intellectual property rights are reserved. You may view and/or print
            pages from <a href="https://www.cecalamusic.com">www.cecalamusic.com</a> for your own personal use subject to restrictions set in these terms and conditions.</p>
          <p>You must not:</p>
          <List ordered>
            <List.Item>Republish material from <a href="https://www.cecalamusic.com">www.cecalamusic.com</a></List.Item>
            <List.Item>Sell, rent or sub-license material from <a href="https://www.cecalamusic.com">www.cecalamusic.com</a></List.Item>
            <List.Item>Reproduce, duplicate or copy material from <a href="https://www.cecalamusic.com">www.cecalamusic.com</a></List.Item>
          </List>
          <p>Redistribute content from Cecala Music (unless content is specifically made for redistribution).</p>

          <Header as='h3'>User Comments</Header>
          <List ordered>
            <List.Item>This Agreement shall begin on the date hereof.</List.Item>
            <List.Item>
              Certain parts of this website offer the opportunity for users to post and exchange opinions, information,
              material and data ('Comments') in areas of the website. Cecala Music does not screen, edit, publish
              or review Comments prior to their appearance on the website and Comments do not reflect the views or
              opinions of Cecala Music, its agents or affiliates. Comments reflect the view and opinion of the
              person who posts such view or opinion. To the extent permitted by applicable laws Cecala Music shall
              not be responsible or liable for the Comments or for any loss cost, liability, damages or expenses caused
              and or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this
              website.
              </List.Item>
            <List.Item>Cecala Music reserves the right to monitor all Comments and to remove any Comments which it considers
            in its absolute discretion to be inappropriate, offensive or otherwise in breach of these Terms and Conditions.
              </List.Item>
            <List.Item>You warrant and represent that:
                    <List ordered>
                <List.Item>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</List.Item>
                <List.Item>The Comments do not infringe any intellectual property right, including without limitation copyright,
                            patent or trademark, or other proprietary right of any third party;</List.Item>
                <List.Item>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material
                            or material which is an invasion of privacy</List.Item>
                <List.Item>The Comments will not be used to solicit or promote business or custom or present commercial activities
                            or unlawful activity.</List.Item>
              </List>
            </List.Item>
            <List.Item>
              You hereby grant to <strong>Cecala Music, LLC</strong> a non-exclusive royalty-free license to use, reproduce,
                edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats
                or media.</List.Item>
          </List>

          <Header as='h3'>Hyperlinking to our Content</Header>
          <List ordered>
            <List.Item>The following organizations may link to our Web site without prior written approval:
                    <List ordered>
                <List.Item>Government agencies;</List.Item>
                <List.Item>Search engines;</List.Item>
                <List.Item>News organizations;</List.Item>
                <List.Item>Online directory distributors when they list us in the directory may link to our Web site in the same
                        manner as they hyperlink to the Web sites of other listed businesses; and</List.Item>
                <List.Item>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls,
                        and charity fundraising groups which may not hyperlink to our Web site.</List.Item>
              </List>
            </List.Item>
          </List>
          <List start="2">
            <List.Item>These organizations may link to our home page, to publications or to other Web site information so long
            as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products or services; and (c) fits within the context of the linking
            party's site.
                </List.Item>
            <List.Item>We may consider and approve in our sole discretion other link requests from the following types of organizations:
                    <List ordered>
                <List.Item>commonly-known consumer and/or business information sources such as Chambers of Commerce, American
                            Automobile Association, AARP and Consumers Union;</List.Item>
                <List.Item>dot.com community sites;</List.Item>
                <List.Item>associations or other groups representing charities, including charity giving sites,</List.Item>
                <List.Item>online directory distributors;</List.Item>
                <List.Item>internet portals;</List.Item>
                <List.Item>accounting, law and consulting firms whose primary clients are businesses; and</List.Item>
                <List.Item>educational institutions and trade associations.</List.Item>
              </List>
            </List.Item>
          </List>
          <p>We will approve link requests from these organizations if we determine that: (a) the link would not reflect
          unfavorably on us or our accredited businesses (for example, trade associations or other organizations
          representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed
          to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from
          the visibility associated with the hyperlink outweighs the absence of Cecala Music; and (d) where the
          link is in the context of general resource information or is otherwise consistent with editorial content
            in a newsletter or similar product furthering the mission of the organization.</p>

          <p>These organizations may link to our home page, to publications or to other Web site information so long as
          the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval
          of the linking party and it products or services; and (c) fits within the context of the linking party's
            site.</p>

          <p>If you are among the organizations listed in paragraph 2 above and are interested in linking to our website,
            you must notify us by sending an e-mail to <a href="mailto:info@cecalamusic.com" title="send an email to info@cecalamusic.com">info@cecalamusic.com</a>.
            Please include your name, your organization name, contact information (such as a phone number and/or e-mail
            address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site,
            and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.</p>

          <p>Approved organizations may hyperlink to our Web site as follows:</p>

          <List ordered>
            <List.Item>By use of our corporate name; or</List.Item>
            <List.Item>By use of the uniform resource locator (Web address) being linked to; or</List.Item>
            <List.Item>By use of any other description of our Web site or material being linked to that makes sense within the
                    context and format of content on the linking party's site.</List.Item>
          </List>
          <p>No use of Cecala Music's logo or other artwork will be allowed for linking absent a trademark license
            agreement.</p>

          <Header as='h3'>Iframes</Header>
          <p>Without prior approval and express written permission, you may not create frames around our Web pages or
            use other techniques that alter in any way the visual presentation or appearance of our Web site.</p>

          <Header as='h3'>Reservation of Rights</Header>
          <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular
          link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also
          reserve the right to amend these terms and conditions and its linking policy at any time. By continuing
            to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</p>

          <Header as='h3'>Removal of links from our website</Header>
          <p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact
          us about this. We will consider requests to remove links but will have no obligation to do so or to respond
            directly to you.</p>
          <p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness
          or accuracy; nor do we commit to ensuring that the website remains available or that the material on the
            website is kept up to date.</p>

          <Header as='h3'>Content Liability</Header>
          <p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify
          and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any
          page on your Web site or within any context containing content or materials that may be interpreted as
          libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
            other violation of, any third party rights.</p>

          <Header as='h3'>Disclaimer</Header>
          <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
          <List ordered>
            <List.Item>limit or exclude our or your liability for death or personal injury resulting from negligence;</List.Item>
            <List.Item>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</List.Item>
            <List.Item>limit any of our or your liabilities in any way that is not permitted under applicable law; or</List.Item>
            <List.Item>exclude any of our or your liabilities that may not be excluded under applicable law.</List.Item>
          </List>
          <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
          are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or
          in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
            (including negligence) and for breach of statutory duty.</p>
          <p>To the extent that the website and the information and services on the website are provided free of charge,
            we will not be liable for any loss or damage of any nature.</p>
					<div className='file-version'>Version: 20169</div>
        </Container>
      </Page>
    );
  }
}
