//
// home.js
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import LessonsPage from './lessons';
import AboutPage from './about';
import NewsPage from './news';

import CMComponent from '../cmComponent';
import { OverviewCard } from '../cards';
import Page from '../page';
import React from "react";
import { CardGroup, Container, Header, Segment, Grid, List } from 'semantic-ui-react';

import adultChoirImage from "../assets/images/adult_choir.svg";
import classesImage from "../assets/images/classes.svg";
import lessonsImage from "../assets/images/lessons.svg";
import workshopsImage from "../assets/images/workshops.svg";

export default class HomePage extends CMComponent {
  componentDidMount() {
    this.props.showLogo(true);
  }

  render() {
    return (
      <Page
        id='home'
        nav='home'
        showLogo={this.props.showLogo}
        setActive={this.props.setActive}
      >
        <Segment basic>
          <div className='slogan'>
            <h1 className='enthusiasm'>Sing with Enthusiasm!</h1>
          </div>
          <Container>
            <Grid centered width={16}>
              <Grid.Row>
                <Grid.Column>
                  <p className='subtitle' style={{ marginBottom: '2rem' }}>
                    A singer who feels confident and connected to the music will always enjoy singing,
                    even when nobody else is listening. We provide a comfortable yet inspiring environment
                    where you will connect with your music.
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <CardGroup centered itemsPerRow={2}>
                    <OverviewCard href='home#classes' title='Online Summer Camps' src={classesImage}>
                      <List size='medium'>
                        <List.Item><List.Content className='overview-content'>Fun group activities</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Immersive learning</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Best tools for singing</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Music-reading independence</List.Content></List.Item>
                      </List>
                    </OverviewCard>
                    <OverviewCard href='home#workshops' title='Workshops' src={workshopsImage}>
                      <List size='medium'>
                        <List.Item><List.Content className='overview-content'>Efficient learning with others</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Quick tips and tricks</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Boost of confidence</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Personalized plan of action</List.Content></List.Item>
                      </List>
                    </OverviewCard>
                  </CardGroup>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <CardGroup centered itemsPerRow={2}>
                    <OverviewCard href='home#private' title='Private Lessons' src={lessonsImage}>
                      <List size='medium'>
                        <List.Item><List.Content className='overview-content'>Solo & audition coaching</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Personalized vocal solutions</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Ongoing broad and deep study</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Focus on preferred genre</List.Content></List.Item>
                      </List>
                    </OverviewCard>
                    <OverviewCard href='home#ensembles' title='Performance Ensembles' src={adultChoirImage}>
                      <List size='medium'>
                        <List.Item><List.Content className='overview-content'>Positive social connections</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Relevant contemporary music</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Vibrant Harmonies</List.Content></List.Item>
                        <List.Item><List.Content className='overview-content'>Dynamic performances</List.Content></List.Item>
                      </List>
                    </OverviewCard>
                  </CardGroup>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12} textAlign='center'>
                  <Segment>
                    <Header as='h3' color='blue'>
                      All lessons are currently taught online via Zoom, Skype, or FaceTime
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
        <hr style={{ marginTop: '2.5rem', visibility: 'hidden' }} />
        <AboutPage
          showLogo={this.setLogo}
          setActive={this.props.setActive}
        />
        <hr style={{ marginTop: '2.5rem', visibility: 'hidden' }} />
        <LessonsPage
          showLogo={this.setLogo}
          setActive={this.props.setActive}
          selectService={this.props.selectService}
        />
        <hr style={{ marginTop: '2.5rem', visibility: 'hidden' }} />
        <NewsPage
          showLogo={this.setLogo}
          setActive={this.props.setActive}
        />
      </Page>
    );
  }
}
