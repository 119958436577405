//
// page.js
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from './cmComponent';
import React from 'react';
import { Container, Grid, Header, Visibility } from 'semantic-ui-react';
import './css/page.css'

export default class Page extends CMComponent {
  constructor(props) {
    super(props);
    this.handleVisibility = this.handleVisibility.bind(this);
  }

  componentDidMount() {
    if (this.props.showLogo) {
      this.props.showLogo(false);
    }
  }  
  
  handleVisibility(data) {
    if (data.calculations.onScreen) {
      this.props.setActive(this.props.nav);
    }
  }

  render() {
    return (
      <Container>
        <Anchor id={this.props.id}>
            <Visibility onUpdate={(e, data) => {
              if (data.calculations.onScreen) {
                this.handleVisibility(data)
              }
            }}>
            </Visibility>
        </Anchor>
        <Title text={this.props.title} />
        {this.props.subtitle ? <SubTitle text={this.props.subtitle} /> : null}
        {this.props.children}
      </Container>
    )
  }
}

export class Anchor extends CMComponent {
  render() {
    return (
      <div className='anchor'>
        <div id={this.props.id} className='anchor-offset'></div>
          {this.props.children}
      </div >
    )
  }
}
export class Title extends CMComponent {
  render() {
    return (
      <Header
        as='h1'
        attached='top'
        textAlign='center'
        className='title'
        color='blue'
      >
        {this.props.text}
      </Header>
    )
  }
}

export class SubTitle extends CMComponent {
  render() {
    return (
      <Grid centered>
        <Grid.Column width={12}>
          <h3 className='subtitle'>{this.props.text}</h3>
        </Grid.Column>
      </Grid >
    )
  }
}
