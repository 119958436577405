//
// privacy.js
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from '../cmComponent';
import Page from '../page';
import React from "react";
import { Container, Header } from 'semantic-ui-react';

export default class PrivacyPage extends CMComponent {
  render() {
    return (
      <Page 
        id='privacy' 
        nav='home' 
        title='Privacy Policy' 
        showLogo={this.props.showLogo} 
        setActive={this.props.setActive}
      >
        <Container>
          <Header as='h3'>Your privacy is critically important to us.</Header>

          <p>It is the policy of Cecala Music, LLC to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a href="https://www.cecalamusic.com">www.cecalamusic.com</a> (hereinafter, "us", "we", "Cecala Music", or "www.cecalamusic.com"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
          <p>This Privacy Policy, together with the Terms and Conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>

          <Header as='h3'>Website Visitors</Header>
          <p>Like most website operators, Cecala Music collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Cecala Music's purpose in collecting non-personally identifying information is to better understand how Cecala Music's visitors use its website. From time to time, Cecala Music may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
          <p>Cecala Music also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on <a href="https://www.cecalamusic.com">www.cecalamusic.com</a> blog posts. Cecala Music only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>

          <Header as='h3'>Gathering of Personally-Identifying Information</Header>
          <p>Certain visitors to Cecala Music's websites choose to interact with Cecala Music in ways that require Cecala Music to gather personally-identifying information. The amount and type of information that Cecala Music gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at <a href="https://www.cecalamusic.com">www.cecalamusic.com</a> to provide a username and email address.</p>

          <Header as='h3'>Security</Header>
          <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

          <Header as='h3'>Advertisements</Header>
          <p>Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Cecala Music and does not cover the use of cookies by any advertisers.</p>

          <Header as='h3'>Links To External Sites</Header>
          <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
          <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>

          <Header as='h3'>Protection of Certain Personally-Identifying Information</Header>
          <p>Cecala Music discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Cecala Music's behalf or to provide services available at Cecala Music's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Cecala Music's website, you consent to the transfer of such information to them. Cecala Music will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Cecala Music discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Cecala Music believes in good faith that disclosure is reasonably necessary to protect the property or rights of Cecala Music, third parties or the public at large.</p>
          <p>If you are a registered user of <a href="https://www.cecalamusic.com">www.cecalamusic.com</a> and have supplied your email address, Cecala Music may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Cecala Music and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Cecala Music takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p>

          <Header as='h3'>Aggregated Statistics</Header>
          <p>Cecala Music may collect statistics about the behavior of visitors to its website. Cecala Music may display this information publicly or provide it to others. However, Cecala Music does not disclose your personally-identifying information.</p>

          <Header as='h3'>Cookies</Header>
          <p>To enrich and perfect your online experience, Cecala Music uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.</p>
          <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Cecala Music uses cookies to help Cecala Music identify and track visitors, their usage of <a href="https://www.cecalamusic.com">www.cecalamusic.com</a>, and their website access preferences. Cecala Music visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Cecala Music's websites, with the drawback that certain features of Cecala Music's websites may not function properly without the aid of cookies.</p>
          <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Cecala Music's use of cookies.</p>

          <Header as='h3'>Privacy Policy Changes</Header>
          <p>Although most changes are likely to be minor, Cecala Music may change its Privacy Policy from time to time, and in Cecala Music's sole discretion. Cecala Music encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
					<div className='file-version'>Version: 20169</div>
        </Container>
      </Page>
    );
  }
}
