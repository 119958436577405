//
// policy.jsx
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from '../cmComponent';
import Page from '../page';
import React from "react";
import { Container, Header, List } from 'semantic-ui-react';

export default class PolicyPage extends CMComponent {
	render() {
		return (
			  <Page 
				  id='policy' 
				  nav='home' 
				  title='Studio Policy' 
				  showLogo={this.props.showLogo} 
				  setActive={this.props.setActive}
				>
				<Container>
					<p>
						Welcome! Please read through the following policy to develop an understanding of what we expect
						of those who study with Cecala Music. We value every relationship and hope that you will let us
						know if there is anything we can do to better ensure that you have a great experience with us.
					</p>
					<Header as='h3'>Online Student Portal</Header>
					<List bulleted>
						<List.Item>
							After signing up for lessons, we will send you an email containing login information for your
							student portal. After your account setup, you may login through our website.
							</List.Item>
						<List.Item>
							The member login area is your one-stop communication and resource hub where all things pertaining to your music
							lessons are centralized and at your fingertips.
							</List.Item>
						<List.Item>
							Created by My Music Staff, your student portal provides easy access to: your lesson schedule
							and studio calendar, registration for other classes or lessons of interest, studio news and tips,
							lesson notes and practice logs, recordings, sheet music, PDFs, notes and communication from us,
							account history and online payments, and many other wonderful resources to enhance your lesson
							experience with us.
							</List.Item>
						<List.Item>Please note: this is still a newly-added feature for us and it’s in the growing stage, 
							but it should soon turn into a huge library of resources for you.
						</List.Item>
					</List>

					<Header as='h3'>Tuition Payment and Fees</Header>
					<List bulleted>
						<List.Item>
							Students and families may sign up for music lessons at any point during the year and may even
							start in the middle of a month. We will prorate the number of weeks remaining in your first month.
						</List.Item>
						<List.Item>
							For private lessons only, an initial registration fee will be included on your first invoice.
							(The cost of registration is included in tuition for online classes and workshops.) Teaching is the 
							visible part of what we do, but there is much more happening behind the scenes to ensure a smooth and 
							rewarding experience for you, and the registration fee pays for these administrative and overhead costs.
						</List.Item>
						<List.Item>
							We accept all major credit cards — Visa, MasterCard, Discover and American Express; We also accept Venmo, Paypal, cash, or check; however, for
							subscriptions, automatic payments are required. You may select and set up your preferred payment method through your online student portal.
						</List.Item>
						<List.Item>When you sign up for a monthly subscription to pay for tuition, you’ll receive an ongoing discounted
						rate per lesson. We process payments at the beginning of each month and are unable to issue refunds
								once that month’s payment has cleared.</List.Item>
						<List.Item>
							If it becomes necessary to take some time off, or pause your subscription, please email us at
							info@cecalamusic.com at least ten days before the next billing. Once you miss three months of charges,
							we’ll have to charge another registration fee upon your return.
							</List.Item>
						<List.Item>You may choose to pay-as-you-go for flexibility. In that case, lesson payment is
						“due on receipt” for each lesson, and will be charged a late fee if not paid within a week after
						the lesson. (Venmo is a typical method of payment for this option.)
							</List.Item>
						<List.Item>We understand that financial or time constraints can creep up on us now and then. Please don’t
						hesitate to contact us if any issues like this should arise and we’ll discuss any possible
						solutions to help you to be able to continue lessons with us.
							</List.Item>
						<List.Item>
							We teach only 48 weeks out of the year, and take four weeks of vacation time (as outlined below).
							Those 48 payments are spread evenly throughout the year, charging for only four lessons per month.
							</List.Item>
						<List.Item>For subscriptions, if tuition is not paid by the 10th of each month, we reserve the right to suspend and/or cancel
						lessons for the student, which may result in the student’s lesson time being assigned to
						another student.
							</List.Item>
						<List.Item>Tuition is not prorated or refunded for student absences (see Student Lesson Cancellation
						Policy below).
							</List.Item>
					</List>

					<Header as='h3'>Scheduling and Cancellations</Header>

					<h4>Lesson Time</h4>
					<p>We expect lessons to begin and end on time.  Students who are late will receive the time remaining
					in their scheduled lesson time, although a lesson may occasionally be extended if time permits.
					In the event that your lesson is delayed due to technical difficulties (online lessons), another
					student, or because the teacher is late, we will either extend your lesson or reschedule at no
					additional cost at the discretion of the teacher.
						</p>

					<h4>Student Cancellations</h4>
					<p>
						You may control your lesson schedule from the dashboard or calendar in your student portal.
						If it becomes necessary to miss a lesson, please take the following steps to make sure it is
						taken care of:
					</p>
					<List bulleted>
						<List.Item>
							The studio will have a few “Open Lesson” slots (in gray) scheduled on the calendar each week.
							When you learn in advance of an upcoming conflict (for travel, school, or other
							important events) we expect you to cancel that scheduled lesson so it can become an
							“Open Lesson” for others to schedule as far in advance as possible. You will then receive a
							make-up credit to be able to schedule another “Open Lesson” as your make-up lesson.
						</List.Item>	
						<List.Item>Make-up credits must be used within sixty days of your original missed lesson.</List.Item>
						<List.Item>
							You must notify the studio at least 24 hours before your lesson in order to receive a
							make-up credit. (Medical or family emergencies are the only exception to this rule.)
						</List.Item>
						<List.Item>Tuition will not be refunded or prorated for missed private lessons.</List.Item>
						<List.Item>A student’s scheduled lesson time will rarely be changed, unless a permanent (ongoing) change
						is arranged with Cecala Music.
							</List.Item>
					</List>

					<h4>Teacher Cancellations</h4>
					<p>
						If your instructor is unable to teach a lesson, she will work with you to schedule a make-up lesson as soon as
						possible, when your schedule permits, following the process outlined above.
						</p>

					<h4>Holidays and Teacher Vacation</h4>
					<List bulleted>
						<List.Item>
							The studio is closed the last two weeks of December and for two other weeks (including Thanksgiving weekend)
							during the year. In addition, the studio is closed on each of the following holidays: New Year&apos;s Day,
							Memorial Day, Independence Day, and Labor Day
							</List.Item>
						<List.Item>
							Lessons that fall on scheduled holidays (as listed above) will be rescheduled upon request. Many schools and
							businesses observe additional holidays (e.g., MLK Day, Presidents Day, etc.). Please do not assume Cecala Music
							is closed because public schools or other businesses are closed.
							</List.Item>
						<List.Item>
							Because four weeks of vacation are built into our payment structure, tuition will not be credited or prorated
							due to these vacation weeks. The lessons that fall during these weeks will not be rescheduled. This is fully
							explained under the “Tuition Payment and Fees” section above.
							</List.Item>
					</List>

					<Header as='h3'>Discontinuing Lessons</Header>
					<List bulleted>
						<List.Item>
							We require a written notice by email before the 20th of the month prior to your discontinuing lessons. Please send to:
							info@cecalamusic.com
							</List.Item>
						<List.Item>The required advance notice gives us the time necessary to fill your reserved slot with a new student.</List.Item>
						<List.Item>
							If you desire to take time off in the summer, you may continue paying for your reserved time slot
							so it will remain available when you come back, or you may lose your reserved time and possibly be moved to the
							waiting list until a time becomes available for you again. Remember, lessons may be combined or
							rescheduled at a more convenient time if we need to work around vacations, etc.
							</List.Item>
						<List.Item>
							Tuition will not be refunded if you withdraw from lessons before the end of a month. However, if
							proper notice of withdrawal is given, tuition for the following month may be prorated if you plan
							to continue into the next month but are unable to receive all of your lessons.
							</List.Item>
					</List>

					<Header as='h3'>Performances and Extras</Header>
					<h4>Performances</h4>
					<p>
						Performances are an important part of building a student&apos;s confidence as a musician. We hold at
						least two concerts per year for our students to master the art of live performance and showcase
						their progress to friends and family. We have loved watching each student grow and progress over
						the years at past performances!  These are fun opportunities that are available to all students and
						are included in your tuition with no extra fees.
						</p>
					
					<h4>Supplies</h4>
					<p>
						Students will be advised of the supplies/materials (books, accompaniment tracks, sheet music, etc.) required
						for their lessons, and where they may purchase them. We will provide any necessary materials
						through their student portal. Students are responsible for purchasing any sheet music they wish to keep.
						</p>

					<h4>Extra Lessons</h4>
					<p>
						There are many situations in which students need to schedule extra lessons outside of their normal, reserved
						schedule. Examples may include extra preparation for an audition, making up for a cancelled lesson, or
						rescheduling around a family vacation or other significant event. Please feel free to schedule "Open Lesson" slots
						for this purpose on the studio calendar, and you will be charged the same rate per lesson as you are currently paying 
						on your next invoice. The same cancellation policy applies as for ongoing reserved lessons.
						</p>

					

					<Header as='h3'>Contact Information and Email Use</Header>
					<List bulleted>
						<List.Item>We use email as our primary way of communicating with students/parents. If a student changes his or her email
						address, it is important to notify the studio as soon as possible. If the studio does not have a correct email
						address on file, students may miss important notices regarding recitals, studio closings, etc.
							</List.Item>
						<List.Item>Students should advise the studio if any contact information (phone number(s), address, etc.) changes.</List.Item>
						<List.Item>Students may need to add our email address as an allowed sender to his or her email so that a spam filter does
						not catch the studio&apos;s emails. If you do not receive regular emails from us regarding studio events (performance
						opportunities, studio closures, etc.), please let us know!
						</List.Item>
						<List.Item>
							By agreeing to this policy, you specifically acknowledge and consent to the receipt of non-marketing emails from 
							Cecala Music, at any accounts you provide to us. You may also opt-in to the receipt of marketing emails upon request.
						</List.Item>
					</List>

					<Header as='h3'>Security and Privacy Notice</Header>
					<p>Our first concern is for the welfare and safety of our students and for that of our teachers and
					studio. For this reason, as well as for coaching and training purposes, we may use a combination of proper
					protocol (i.e. studio policy, contracts, waivers, and background checks) along with a variety of monitoring or 
					surveillance tools including but not limited to cameras, microphones, and similar devices. To the extent permitted 
					by law, we reserve the right to record lessons, telephone conversations, and other communications consistent with our privacy
					policy. We encourage you to raise any questions or concerns you may have regarding these measures.
						</p>

					<Header as='h3'>Photos and Video</Header>
					<p>From time-to-time, we may take photos and video of students at performances or in lessons,
					for use in our online or print marketing. If you prefer that we not use your (or your student&apos;s)
					likeness, please email us at info@cecalamusic.com and we will happily honor your request.
						</p>

					<Header as='h3'>Subject to Change</Header>
					<p>All of the policies herein are subject to change at our discretion.</p>
					<p>Thank you for taking the time to read through to the end. Now let&apos;s sing!</p>
					<div className='file-version'>Version: 20169</div>
				</Container>
			</Page>
		);
	}
}
