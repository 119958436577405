//
// news.js
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from '../cmComponent';
import Page, { Anchor } from '../page';
import React from "react";
import { Container, Grid, Header, Image, Label, List, Segment } from 'semantic-ui-react';
import '../css/page.css';

import adultFundamentalsImage from '../assets/images/adult_fundamentals.svg';
import anneNewsImage from '../assets/images/anne_news.jpg';
import pianoSingersImage from '../assets/images/piano_singers.svg';

export default class NewsPage extends CMComponent {
  render() {
    return (
      <Page id='news' nav='news' title='Studio News' setActive={this.props.setActive}>
        <Anchor id='soon'>
          <Header as='h2' dividing color='purple'>Announcements</Header>
        </Anchor>
        <Grid centered stackable columns={2} className='article'>
          <Grid.Row>
            <Grid.Column width={4}>
              <Image src={anneNewsImage} alt='Anne' rounded style={{ marginTop: '5rem' }} />
            </Grid.Column>
            <Grid.Column width={12}>
              <Container text>
                <Label ribbon color='teal' size='large' style={{ marginTop: '1rem' }}>Announcing new classes and ensembles</Label>
                <Segment>
                  <Header as='h4' dividing>June 18, 2020</Header>
                  <p>Dear Friends,</p>
                  <p>Once in awhile, life will throw us a curveball or two and I think I’m safe to say that the last several months have been no exception for any of us. But I’ve never been one to stay down for long, so I’ve been doing a lot of thinking, learning, praying and planning, and now, I’m so excited to share with you what has resulted from it all!</p>
                  <p>Before we knew anything about Covid-19, Brandon and I had made the decision to expand our business and find ways to reach out and help more people through other means besides private lessons alone. Then when Covid hit, it was only a huge confirmation that we were on the right track. We were constantly feeling and seeing the need for more people to realize the benefits of singing because of how powerful and healing it is. So even though this pandemic has tried to stifle our ability to share and make music with others, instead, it has created a much larger need for music, and singing in particular, for so many people.</p>
                  <p><strong>In regard to all of that, we are so thrilled to finally unveil the new additions to our studio line up!</strong> We are currently accepting enrollment for our two online summer camps called <a href='/#classes'>Music FUNdamentals</a> and <a href='/#classes'>Singing FUNdamentals</a>, because what's Summer without FUN? I am SUPER excited to be teaching these not only because of all the great activities we have planned, but because of how much everyone will gain from it by interacting with and learning from each other. We're going to have a blast!</p>
                  <p>We've also scheduled two great monthly workshops for adults called <a href='/#workshops'>Practically-Perfect Practice</a>, for parents of music students taking lessons anywhere (not only from us), and <a href='/#workshops'>Singing for Non-Singers</a>, a 'non-singer' prerequisite for our adult Singing Fundamentals class in the Fall. Please see our <a href='/lessons'>Voice Lessons</a> page for all details and registration.</p>
                  <p><strong>And now for our most exciting news!... We will be starting TWO new contemporary performance ensembles &mdash; one for children AND one for adults!</strong> (Calling all alumni from local children's choirs...) I can't wait to not only have a place to share my passion for directing talented singers of all ages, but also to produce incredible harmonies and enjoy making amazing music together! And when it comes to style, there will be no skimping on the <i>coolness factor</i>, I assure you. This will be so much fun!</p>
                  <Header as='h3'>Auditions and Dates for the New Performance Ensembles</Header>
                  <p><strong>July 15th to August 1st - video audition submissions</strong></p>
                  <p>(please fill out our <a href='/contact'>contact form</a> and we'll email instructions to all who are interested)</p>
                  <p><strong>August 8th and 15th - callbacks</strong></p>
                  <p><strong>September 1st and 3rd - rehearsals begin</strong></p>
                  <p>Depending on location availability, we plan to hold rehearsals in Salt Lake County on Tuesdays, and rehearsals in Utah County on Thursdays.</p>
                  <Header as='h3'>Fees & Tuition</Header>
                  <List bulleted>
                    <List.Item>Annual Registration Fee - $50 plus $25 for each additional family member (due after admission)</List.Item>
                    <List.Item>Children's Ensemble will be $800 / year or $67 / month for 12 months.</List.Item>
                    <List.Item>Adult Ensemble will be $400 / year or $34 / month for 12 months.</List.Item>
                    <List.Item>Wardrobe fees are still to be determined, but we will do our best to keep costs as low as possible while still looking great.</List.Item>
                  </List>
                  <p>Be watching for updates as more information rolls out in the coming weeks. We are so thrilled that all of this is happening and can't wait to include you in all of the fun and excitement! In the meantime, feel free to browse our website, get to know us better, learn more about the different classes and lessons we have coming up, and <strong>pass it along!</strong> For regular email updates as we roll out more information, please fill out our <a href='/contact'>contact form</a> and we'll put you on our mailing list. We feel confident that you will be pleased with what we have to offer, and we hope to be able to serve you in the very near future!</p>
                  <p>Kindest regards,</p>
                  <p>Anne Cecala</p>
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Anchor id='soon'>
          <Header as='h2' dividing color='purple'>Coming Soon</Header>
        </Anchor>
        <Segment id='future' basic>
          <List>
            <List.Item><Header as='h4'><Image src={pianoSingersImage} />Piano for Singers</Header></List.Item>
            <List.Item><Header as='h4'><Image src={adultFundamentalsImage} />Singing Fundamentals for Adults</Header></List.Item>
          </List>
        </Segment>
      </Page>
    );
  }
}
