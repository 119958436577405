//
// App.js
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import HomePage from './pages/home';
import BookingPage from './pages/booking';
import ContactPage from './pages/contact';
import LoginPage from './pages/login';
import PrivacyPage from './pages/privacy';
import TermsPage from './pages/terms';
import PolicyPage from './pages/policy';
import Footer from './footer';
import { NavBar, NavButton } from './nav';
import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { Container, Label, Visibility } from 'semantic-ui-react';
import Validator from './validator';

const bookingValidator = new Validator({
	type: 'object',
	additionalProperties: false,
	properties: {
		studentName: { type: "string", pattern: "^[A-Za-z][A-Za-z0-9'_ -]*[A-Za-z0-9]$", maxLength: 256 },
		parentName: { type: "string", pattern: "^[A-Za-z][A-Za-z0-9'_ -]*[A-Za-z0-9]$", maxLength: 256, nullable: true },
		email: { type: "string", format: 'email' },
		service: { type: "string", minLength: 1, maxLength: 512 },
		services: { type: "array", items: { type: "string", format: 'uuid' }, minItems: 1 },
		marketing: { type: "boolean" },
		policy: { type: "boolean", const: true },
		comments: { type: "string", minLength: 1, maxLength: 4096, nullable: true },
	}
}, {
	studentName: 'Invalid student name.',
	parentName: 'Invalid parent name.',
	email: 'Invalid email.',
	service: 'Invalid option.',
	services: 'You must choose at least one option.',
	marketing: 'Please choose a marketing option.',
	policy: 'You must accept the studio policy to request a booking.',
	comments: 'Unrecognizable character in comments.'
});

const contactValidator = new Validator({
	type: 'object',
	additionalProperties: false,
	properties: {
		name: { type: "string", pattern: "^[A-Za-z][A-Za-z0-9'_ -]*[A-Za-z0-9]$", maxLength: 256 },
		email: { type: "string", format: 'email' },
		subject: { type: "string", minLength: 1, maxLength: 256 },
		comments: { type: "string", minLength: 1, maxLength: 4096 },
		marketing: { type: "boolean" }
	}
}, {
	name: 'Invalid name.',
	email: 'Invalid email.',
	subject: 'Please choose a subject.',
	comments: 'Unrecognizable character in comments.',
	marketing: 'Please choose a marketing option.'
});

bookingValidator.init();
contactValidator.init();
export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasLogo: true,
			activePage: 'home',
			error: null,
			services: [],
			selectedService: null,
			mastHeadPercentage: 1
		}

		this.setLogo = this.setLogo.bind(this);
		this.setActive = this.setActive.bind(this);
		this.handleMastheadUpdate = this.handleMastheadUpdate.bind(this);
		this.handleSelectService = this.handleSelectService.bind(this);
	}

	async componentDidMount() {
		const response = await fetch(`${this.props.apiUrl}/products`);
		const services = await response.json();
		this.setState({ services: services });
	}

	setLogo(enabled) {
		this.setState({ hasLogo: enabled });
	}

	setActive(page) {
		this.setState({ activePage: page });
	}

	handleMastheadUpdate(e, { calculations }) {
		const percentage = Math.min(1, Math.max(0, 1 - 2 * calculations.percentagePassed));
		this.setState({
			mastHeadPercentage: percentage
		})
	}

	handleSelectService(serviceId) {
		this.setState({ selectedService: serviceId });
	}

	render() {
		return (
			<Router>
				<Visibility continuous onUpdate={this.handleMastheadUpdate}>
					<NavBar>
						<NavButton
							iconName='home'
							label='Home'
							nav='home'
							href='/'
							active={this.state.activePage === 'home'}
						/>
						<NavButton
							iconName='info circle'
							label='About Us'
							nav='about'
							href='/#about'
							active={this.state.activePage === 'about'}
						/>
						<NavButton
							iconName='music'
							label='Voice Lessons'
							nav='lessons'
							href='/#lessons'
							active={this.state.activePage === 'lessons'}
						/>
						<NavButton
							iconName='newspaper'
							label='Studio News'
							nav='news'
							href='/#news'
							active={this.state.activePage === 'news'}
						/>
						<NavButton
							iconName='calendar check'
							label='Book Now'
							nav='booking'
							href='/booking'
							active={this.state.activePage === 'booking'}
						/>
						<NavButton
							iconName='envelope'
							label='Contact Us'
							nav='contact'
							href='/contact'
							active={this.state.activePage === 'contact'}
						/>
						<NavButton
							iconName='user'
							label='Member Login'
							nav='login'
							href='/login'
							active={this.state.activePage === 'login'}
						/>
					</NavBar>
					<div className='masthead' style={{ display: (this.state.hasLogo ? 'block' : 'none') }} >
						<div className='logo'>
							<div
								className='img'
								style={{
									display: (this.state.hasLogo ? 'block' : 'none'),
									opacity: this.state.mastHeadPercentage
								}}
							></div>
						</div>
						<Label
							href='/#news'
							color='pink'
							size='large'
							attached='bottom left'
							style={{
								opacity: this.state.mastHeadPercentage
							}}
						>
							<h2 className='announcement-title'>NEW Classes & Ensembles</h2>
							<p className='announcement-subtitle'>Click for audition info and a message from Anne </p>
						</Label>
					</div>
				</Visibility>
				<Container>
					{this.state.selectedService ? <Navigate to='/booking' /> : null}
					<Routes>
						<Route path="/booking" element={
							<BookingPage
								services={this.state.services}
								formAction={`${this.props.apiUrl}/bookings`}
								selectedService={this.state.selectedService}
								recaptchaSiteKey={this.props.recaptchaSiteKey}
								validator={bookingValidator}
								showLogo={this.setLogo}
								setActive={this.setActive}
							/>
						} />
						<Route path="/contact" element={
							<ContactPage
								formAction={`${this.props.apiUrl}/messages`}
								recaptchaSiteKey={this.props.recaptchaSiteKey}
								validator={contactValidator}
								showLogo={this.setLogo}
								setActive={this.setActive}
							/>
						} />
						<Route path="/login" element={
							<LoginPage
								showLogo={this.setLogo}
								setActive={this.setActive}
							/>
						} />
						<Route path="/privacy" element={
							<PrivacyPage
								showLogo={this.setLogo}
								setActive={this.setActive}
							/>
						} />
						<Route path="/terms" element={
							<TermsPage
								showLogo={this.setLogo}
								setActive={this.setActive}
							/>
						} />
						<Route path="/policy" element={
							<PolicyPage
								showLogo={this.setLogo}
								setActive={this.setActive}
							/>
						} />
						<Route path="/" element={
							<HomePage
								showLogo={this.setLogo}
								setActive={this.setActive}
								selectService={this.handleSelectService}
							/>
						} />
						<Route path="/home#lessons" element={
							<HomePage
								showLogo={this.setLogo}
								setActive={this.setActive}
								selectService={this.handleSelectService}
							/>
						} />
					</Routes>
					<Footer />
				</Container>
			</Router>
		);
	}
}
