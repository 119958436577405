//
// about.jsx
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from '../cmComponent';
import Page from '../page';
import React from "react";
import { Header, Image, Segment, Grid } from 'semantic-ui-react';

import anneImage from "../assets/images/anne_governors.jpg";
import brandonImage from "../assets/images/brandon_singing.jpg";
import cecalaImage from '../assets/images/central_park.jpg';

export default class AboutPage extends CMComponent {
  render() {
    return (
      <Page 
        id='about' 
        nav='about' 
        title='About Us' 
        subtitle={`
          Anne and Brandon Cecala founded Cecala Music to combine Anne's music
          education experience with Brandon's production expertise. Together, they strive for
          excellence, authenticity, integrity, compassion, and dignity.
        `}
        setActive={this.props.setActive}
      >
        <Header as='h2' dividing color='purple'>Cecala Music</Header>
        <Segment id='company' basic>
          <Grid centered>
            <Grid.Column width={6}>
              <figure className='about'>
                <Image src={cecalaImage} alt="Anne and Brandon in Central Park" rounded />
                <figcaption>Anne and Brandon in Central Park</figcaption>
              </figure>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className='pronunciation'>Pronounced: S&#x0259;&#183;kah&#180;&#183;l&#x0259;</p>
              <p>
                Currently located in Salt Lake County, Cecala Music has been serving Northern Utah's
                music education and production needs since 2014. Anne and Brandon met while performing together at
                Hale Centre Theatre, each with a previous track record of success in the arts. While they are both performers,
                Anne specializes in teaching and coaching music students, and Brandon handles the
                technology side including recording, live sound, and web technologies. When not sharing music, they love travel,
                philosophy, food, family, and their quirky cat, Ollie.
              </p>
            </Grid.Column>
          </Grid>
        </Segment>
        <Header as='h2' dividing color='purple'>
          Anne Cecala
            <Header sub>Co-founder</Header>
        </Header>
        <Segment id='anne' basic>
          <Grid>
            <Grid.Column width={10}>
              <p>
                Spending much of her life on the stage, Anne has a deep passion and connection to the music and theatre 
                industries and has the knowledge and experience necessary to help students grow to become the best performers 
                they can be. After teaching and coaching voice and piano for 27 years, nothing energizes her more than 
                participating in her students' "a-ha!" moments and discovering their "genius within".
              </p>
              <p>
                Anne recently served as executive director of the world-renowned One Voice Children’s Choir, 
                representing the culmination of eight years of service as the choir's assistant director, accompanist, 
                and resident vocal performance coach. During her tenure, working alongside artistic director, Masa Fukuda, the 
                choir won Utah's Best of State award for six consecutive years, winning the coveted BOSS statue for the 
                Arts and Entertainment category two of those years. They performed for President Obama at the White House and at 
                Radio City Music Hall as quarter-finalists on America's Got Talent. Also during that time, the choir amassed 2 
                million YouTube subscribers, and received over 300 million views of their videos.
              </p>
              <p>
                Some of Anne's favorite collaborations over the years include artists like Train, The Band Perry, 
                David Archuleta, The Piano Guys, Pasek and Paul (The Greatest Showman), Kurt Bestor, Lexi Walker, Alex Boyé, 
                Peter Breinholt, Gentri, Merril Osmond (at Hale Centre Theatre), and David Osmond.
              </p>
              <p>
                Anne adores her three talented children and their distinct personalities, is fascinated by wildlife and the 
                great outdoors, loves to learn and cook, and if she had free time, she would garden more and take 
                up painting.
              </p>
            </Grid.Column>
            <Grid.Column width={6}>
              <figure className='about'>
                <Image src={anneImage} alt="Anne at the Utah Governor's Mansion" rounded />
                <figcaption>
                  Anne at the Utah Governor&apos;s Mansion
            </figcaption>
              </figure>
            </Grid.Column>
          </Grid>
        </Segment>
        <Header as='h2' dividing color='purple'>
          Brandon Cecala
          <Header sub>Co-founder</Header>
        </Header>
        <Segment id='brandon' basic>
          <Grid>
            <Grid.Column width={6}>
              <figure className='about'>
                <Image src={brandonImage} alt="Brandon on stage at Red Butte Garden" rounded />
                <figcaption>
                  Brandon on stage at Red Butte Garden
                </figcaption>
              </figure>
            </Grid.Column>
            <Grid.Column width={10}>
              <p>
                Brandon has been a working artist for many years. A pianist since age seven, he plays mostly by ear. 
                He occasionally composes music, but he is at his best when performing improvisationally. His musical 
                influences include the jazz, rock, pop, punk, and R&B masters.
              </p>
              <p>
                He is also a veteran performer of regional theatre, particularly Hale Centre Theatre,
                where he appeared in 17 productions such as&nbsp;<i>West Side Story</i> (Tony)
                and&nbsp;<i>Curtains</i> (Aaron).
              </p>
              <p>
                You can often find him behind the scenes, working on the production team. As a technical collaborator, 
                Brandon has provided music direction, record production, concert live sound, set design,
                and consulting work. Recently, he served as Cecala Music&apos;s resident sound manager for the 
                One Voice Children&apos;s Choir, mixing live sound for a wide variety of events.
              </p>
              <p>
                Brandon's day jobs have included extensive experience in software engineering management and education.
                He received bachelor&apos;s degrees in economics and mass communication from the University of Utah and he
                holds a commercial pilot's certificate.
              </p>
            </Grid.Column>
          </Grid>
        </Segment>
      </Page>
    );
  }
}
