//
// cards.js
// Copyright 2020-2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from './cmComponent';
import React from "react";
import { Card, Grid, Image, Label } from 'semantic-ui-react'
import './css/cards.css';

export class CMCard extends CMComponent {
  render() {
    return (
      <Card href={this.props.href} onClick={this.props.onClick} style={{ 
		width: '14rem', 
		minWidth: '14rem', 
		maxWidth: '14rem', 
		overflow: 'hidden' 
	  }} raised link>
        <Card.Content style={{ position: 'relative', paddingTop: '1.3rem' }} >
          <Card.Header as='h2' color='purple' size='medium'>
            <Grid columns='2' verticalAlign='top'>
              <Grid.Column width={10}>
                <span style={{ display: 'inline-block'}}>{this.props.title}</span>
              </Grid.Column>
              <Grid.Column width={6}>
                <Image
                  src={this.props.src}
                  verticalAlign='middle'
                />
              </Grid.Column>
            </Grid>
          </Card.Header>
          {this.props.children}
        </Card.Content>
        {this.props.extra?
          <Card.Content extra>
            {this.props.extra}
          </Card.Content>
          : null
        }
        {this.props.act?
          <CallToAction command={this.props.act}/>
          : null
        }
      </Card>
    );
  }
}
export class OverviewCard extends CMComponent {
  render() {
    return (
      <CMCard
        href={this.props.href}
        title={this.props.title}
        src={this.props.src}
        act='Learn More'
      >
        <Card.Description>
          {this.props.children}
        </Card.Description>
      </CMCard>
    );
  }
}

export class ProductCard extends CMComponent {
  render() {
    return(
      <CMCard
        href={this.props.href}
        title={this.props.title}
        src={this.props.src}
        act={this.props.act}
        extra={this.props.offers? <Offer products={this.props.offers} /> : null }
        onClick={this.props.selectService? () => this.props.selectService(this.props.productId) : null}
      >
        {this.props.children}
      </CMCard>
    );
  }
}

export class CallToAction extends CMComponent {
  render() {
    return (
      <Card.Content extra textAlign='center'>
        <Label attached='bottom' color='red'>
          {this.props.command}
        </Label>
      </Card.Content>
    );
  }
}
export class Offer extends CMComponent {
    render() {
        const products = this.props.products.map(p =>
            <div key={`${p.price}${p.terms}`}>
              <div className='price'>${p.price}</div>
              <div className='term'>{p.terms}</div>
            </div>
        );
        return (
          <Card.Content extra>
            <div className='offer'>
              {products}
            </div>
          </Card.Content>
        );
    }
}

export class Price extends CMComponent {
    render() {
        return (
            <div className='price'>
                <p className='value'>${this.props.value}</p>
                <p className='term'>{this.props.term}</p>
            </div>
        );
    }
}
