//
// login.js
// Copyright 2024 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import React, { Component } from 'react';
import Page from '../page';
import '../css/page.css';

export default class LoginPage extends Component {
  widgetContainerRef = React.createRef(); // Create a ref for the widget container

  insertWidgetScript = () => {
    const script = document.createElement('script');
    script.src="https://app.mymusicstaff.com/Widget/v4/Widget.ashx?settings=eyJTY2hvb2xJRCI6InNjaF85NEhKSiIsIldlYnNpdGVJRCI6Indic196Sm5KUyIsIldlYnNpdGVCbG9ja0lEIjoid2JiX1BKd0pxIn0=";
    script.async = true;

    // Insert the script tag directly inside the widget container
    this.widgetContainerRef.current.appendChild(script);
  }

  componentDidMount() {
    // Load the widget script after the component has mounted
    this.insertWidgetScript();
  }

  render() {
    return (
      <Page id='login' nav='login' setActive={this.props.setActive}>
        <div id='login-widget' ref={this.widgetContainerRef}></div>
      </Page>
    );
  }
}
